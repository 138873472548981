<template>
  <div class="about-agency">
    <div class="about-agency__wrapper">
      <Row wrap
           justify="between">
        <Column class="grid-sm-full grid-md-3 grid-lg-3">
          <h2 class="about-agency__title">{{ $t('agencies.about_agency') }}</h2>
        </Column>
        <Column class="grid-sm-full grid-md-8 grid-lg-8">
          <h4 class="about-agency__title">{{title}}</h4>
          <Row class="about-agency__additions"
               justify="between"
               wrap>
            <Column class="grid-sm-full grid-md-4 grid-lg-4">
              <Column v-for="item in arrayA"
                      :key="item.id">
                <p class="about-agency__additions-key">{{item.key}}</p>
                <p class="about-agency__additions-value">{{item.value}}</p>
              </Column>
            </Column>
            <Column class="grid-sm-full grid-md-4 grid-lg-4">
              <Column v-for="item in arrayB"
                      :key="item.id">
                <p class="about-agency__additions-key">{{item.key}}</p>
                <p class="about-agency__additions-value">{{item.value}}</p>
              </Column>
            </Column>
          </Row>
          <div class="about-agency__on-map grid-sm-full">
            <OnMap :location="location || $city.location"
                   :places="{params}"/>
          </div>
          <Row class="about-agency__gallery"
               wrap>
            <div v-for="image in images"
                 :key="image.id"
                 class="about-agency__gallery-image grid-sm-2 grid-md-2 grid-lg-2">
              <ImageComponent :params="image"/>
            </div>
          </Row>
        </Column>
      </Row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutAgency',
  props: {
    params: {},
  },
  data() {
    return {
      arrayA: [],
      arrayB: [],
    };
  },
  created() {
    this.additions.forEach((item) => {
      if (item.id % 2 === 0) {
        this.arrayA = [...this.arrayA, item];
      } else {
        this.arrayB = [...this.arrayB, item];
      }
    });
  },
  computed: {
    id() {
      return this?.params?.id;
    },
    location() {
      return this?.params?.location;
    },
    additions() {
      return this?.params?.additions;
    },
    title() {
      return this?.params?.title;
    },
    images() {
      return this?.params?.images;
    },
  },
};
</script>
